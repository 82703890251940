<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">General Reports Dashboard</h3>
      </div>
      <el-form label-position="top" label-width="10px" :model="reportForm" :rules="rulesReportForm" ref="reportForm">
        <el-row type="flex" class="row-bg" justify="end">
          <el-col>
            <!-- <el-switch active-text="Display Losing Branch"></el-switch> -->
          </el-col>
          <el-col>
            <download-excel style="float: right; background:#EB2C3E; color:white;" class="el-button" :header="reportName" :name="reportName + '-' + formatDate(new Date())" :fetch="generateReport" :fields="json_fields">Download Report</download-excel>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="10">
          <el-col :span="14">
            <el-form-item label="Group By:" prop="selectedGroupBy">
              <el-select style="width:100%;" v-model="reportForm.selectedGroupBy" size="mini" clearable placeholder="Group By" @change="getDepts">
                <el-option v-for="groupBy in groupByList"  :key="groupBy.id" :value="groupBy.id" :label="groupBy.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="10">
          <el-col :span="4">
            <el-form-item label="Report Name:" prop="selectedReport">
              <el-select style="width:100%;" v-model="reportForm.selectedReport" size="mini" clearable placeholder="Report Name" filterable value-key="id">
                <el-option v-for="report in reportTypes" :key="report.id" :value="report" :label="report.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="Fields:" prop="selectedFields">
              <el-select style="width:100%;" v-model="reportForm.selectedFields" size="mini"  placeholder="Fields" clearable filterable multiple value-key="id">
                <el-option v-for="field in fieldList" :key="field.id" :value="field" :label="field.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="reportForm.selectedReport.id === 1">
          <el-row :gutter="10">
            <el-col :span="14">
              <el-form-item label="Work Type:" prop="selectedWorkType">
                <el-select style="width:100%" size="mini" v-model="reportForm.selectedWorkType" filterable placeholder="Work Type">
                  <el-option :value="0" label="All">All</el-option>
                  <el-option v-for="type in types" :key="type.id" :label="type.name" :value="type.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="14">
              <el-form-item label="Location:" prop="selectedLocation">
                <el-select style="width:100%" size="mini" v-model="reportForm.selectedLocation" filterable placeholder="Locations">
                  <el-option :value="0" label="All">All</el-option>
                  <el-option v-for="location in locations" :key="location.id" :label="location.name" :value="location.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="14">
              <el-form-item label="Select Date Range:" prop="selectedDate">
                <el-date-picker v-model="reportForm.selectedDate" type="daterange" size="mini" align="center" style="width:100%;" prop="selectedDate" start-placeholder="Start Date" end-placeholder="End Date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="reportForm.selectedLocation === 557">
            <el-row :gutter="10">
              <el-col :span="14">
                <el-form-item label="Department:" prop="selectedDept">
                  <el-select style="width:100%;" v-model="reportForm.selectedDept" size="mini" clearable placeholder="Department" @change="getSection" filterable multiple>
                    <el-option :value="0" label="All">All</el-option>
                    <el-option v-for="department in departments" :key="department.id" :value="department.id" :label="department.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="14">
                <el-form-item label="Section:" prop="selectedSection">
                  <el-select style="width:100%;" v-model="reportForm.selectedSection" placeholder="Select" multiple size="mini" :disabled="reportForm.selectedDept.length === 0">
                    <el-option-group
                      v-for="group in sections"
                      :key="group.dept"
                      :label="group.dept">
                      <el-option
                        v-for="item in group.options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { format } from 'date-fns'
export default {
  data () {
    return {
      json_data: [],
      inputForm: [],
      types: [],
      locations: [],
      reportForm: {
        selectedDept: [],
        selectedWorkType: [],
        selectedLocation: [],
        selectedSection: [],
        selectedReport: '',
        selectedFields: [],
        selectedDate: ''
      },
      dataForExcel: [
        {
          colA: 'Hello',
          colB: 'World',
          colC: ''
        },
        {
          colA: 'Multi-line',
          /* Multi-line value: */
          colB: 'This is a long paragraph\nwith multiple lines\nthat should show in a single cell.'
        },
        {
          colA: 'Another',
          colB: 'Regular cell'
        },
        {
          colZ: ''
        }
      ],
      rulesReportForm: {
        selectedReport: [
          { required: true, message: 'Please select a report!', trigger: 'change' }
        ],
        selectedWorkType: [
          { required: true, message: 'Please select a work type!', trigger: 'blur' }
        ],
        selectedLocation: [
          { required: true, message: 'Please select a location!', trigger: 'blur' }
        ],
        selectedDate: [
          { required: true, message: 'Please select a date range!', trigger: 'change' }
        ]
      },
      reportTypes: [
        {
          name: 'Work Type Report',
          id: 1
        },
        {
          name: 'Report',
          id: 2
        }
      ],
      fieldList: [
        {
          name: 'Last Name',
          id: 1,
          property_name: 'lastname',
          property_value: 'lastname'
        },
        {
          name: 'First Name',
          id: 2,
          property_name: 'firstname',
          property_value: 'firstname'
        },
        {
          name: 'Department',
          id: 3,
          property_name: 'department',
          property_value: 'bd_id_name'
        },
        {
          name: 'Section',
          id: 4,
          property_name: 'section',
          property_value: 'bds_id_name'
        },
        {
          name: 'Position Title',
          id: 5,
          property_name: 'position title',
          property_value: 'bj_id_name'
        },
        {
          name: 'Date Hired',
          id: 6,
          property_name: 'datehired',
          property_value: 'datehired'
        },
        {
          name: 'Employment Status',
          id: 7,
          property_name: 'employment status',
          property_value: 'bes_id_name'
        },
        {
          name: 'Employee ID',
          id: 8,
          property_name: 'empid',
          property_value: 'empid'
        }
      ],
      groupByList: [
        {
          name: 'All',
          id: 0
        },
        {
          name: 'Support',
          id: 1408
        },
        {
          name: 'Revenue',
          id: 1407
        }
      ],
      yearList: [],
      evalList: [],
      json_fields: {},
      departments: [],
      sections: [],
      reportName: ''
    }
  },
  created () {
    // this.getDepartments()
  },
  mounted () {
    this.getDepts()
    this.getWorkTypes()
    this.getLocation()
  },
  methods: {
    getFields () {
      this.fieldList = []
      this.$http
        .get('reports-field-list/' + this.reportForm.selectedReport.id)
        .then(res => {
          // console.log(res)
          this.fieldList = res.data.body
        })
    },
    formatDate (date) {
      return format(date, 'PPpp')
    },
    getDepts () {
      this.$http
        .get('backend.department-list-by-company/1')
        .then(res => {
          // console.log(res)
          this.departments = res.data.body
        })
    },
    clickButton () {
      // this.$refs.downloadButton.$el.click()
      // console.log(this.$refs.downloadButton)
      this.$refs.downloadButton.click()
    },
    validateFields () {
      if (this.reportForm.selectedReport === '' || this.reportForm.selectedLocation.length === 0 || this.reportForm.selectedWorkType.length === 0 || this.reportForm.selectedDate.length === 0) {
        return false
      } else {
        return true
      }
    },
    async generateReport () {
      this.$refs.reportForm.validate()
      if (this.validateFields()) {
        const jsonFields = {}
        this.reportForm.selectedFields.forEach(e => (
          jsonFields[e.property_name] = e.property_value
        ))
        var formSubmit = {}
        formSubmit.reports_id = this.reportForm.selectedReport.id
        formSubmit.bd_id = this.reportForm.selectedDept
        formSubmit.bbl_id = this.reportForm.selectedLocation
        formSubmit.work_type_bg4_id = this.reportForm.selectedWorkType
        formSubmit.bds_id = this.reportForm.selectedSection
        formSubmit.logdate_from = this.reportForm.selectedDate[0]
        formSubmit.logdate_to = this.reportForm.selectedDate[1]
        formSubmit.fields = jsonFields
        console.log(formSubmit)
        const response = await this.$http.post('general-reports', formSubmit)
        if (response.status === 200) {
          this.$message({
            showClose: true,
            message: 'Form Submitted',
            type: 'success'
          })
          if (Object.prototype.hasOwnProperty.call(response.data, 'json_data')) {
            this.reportName = this.reportForm.selectedReport.name
            this.$refs.reportForm.resetFields()
            this.json_fields = response.data.json_field
            return response.data.json_data
          } else {
            this.$message({
              showClose: true,
              message: 'No reports found!',
              type: 'error'
            })
          }
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: 'Please complete the form.'
        })
      }
    },
    getSection () {
      this.sections = []
      var formSubmit = {}
      formSubmit.bd_id = this.reportForm.selectedDept
      this.$http
        .post('backend.department.section-list-group-by-bd/', formSubmit)
        .then(res => {
          // console.log(res)
          this.sections = res.data.body
        })
    },
    getWorkTypes () {
      this.$http
        .get('backend.global4-list/90')
        .then(res => {
          this.types = res.data.body
        })
    },
    getLocation () {
      this.$http
        .get('locationinfo.locations/1')
        .then(res => {
          this.locations = res.data.body
        })
    }
  }
}
</script>
<style>
  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }

  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__label {
    margin: 0;
    padding: 0 0 0 !important;
    line-height: 0px !important;
  }
  .el-form-item__error {
    padding-top: 0 !important;
    line-height: 0px !important;
  }
</style>
